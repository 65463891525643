import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/shahid/Desktop/Projects/Personal/madrasatulilm.com/src/views/layouts/MainLayout.js";
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import { Box, Breadcrumb, Divider, SEO, Text } from '../../../../views/components';
import books from '../../../../data/books';
import truncate from '../../../../utils/string-utils';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO pageTitle="বুক শপের বইসমূহ" mdxType="SEO" />
    <Box maxWidth={960} margin="0 auto" padding={{
      xs: 3,
      sm: 4
    }} mdxType="Box">
  <Text variant="h2" textAlign="center" mdxType="Text">
    বুক শপের বইসমূহ
  </Text>
  <Divider mdxType="Divider" />
  <Breadcrumb links={[{
        url: '/',
        name: 'নীড়পাতা'
      }, {
        url: '/finance/',
        name: 'আর্থিক ব্যাপার'
      }, {
        url: '/business-of-madrassa/',
        name: 'মাদরাসাতুল ইলম ফুড এন্ড বুক শপ'
      }]} mdxType="Breadcrumb" />
  <Divider mdxType="Divider" />
      <table>
  <tr>
    <th>বই</th>
    <th>প্রচ্ছদ</th>
  </tr>
        {books.map(book => <tr key={book.url}>
          <td>
            <Text variant="h3" mdxType="Text">
              <Link to={`./${book.url}/`} mdxType="Link">{book.name}</Link>
            </Text>
            <Text mdxType="Text">{truncate(book.description, 100)}{' '}<Link to={`./${book.url}/`} mdxType="Link">বিস্তারিত</Link></Text>
          </td>
          <td><Link to={`./${book.url}/`} className="table-book-cover" mdxType="Link"><img src={`/assets/images/${book.image}`} alt={book.name} /></Link></td>
        </tr>)}  
      </table>
      {
        /* <ul>
        {books.map(book => <li key={book.url}><Link to={`./${book.url}/`}>{book.name}</Link></li>)}
        <li><Link to="../">অন্যান্য পণ্য (যেমন: নধু, ঘি, তেল, 4ডি ব্লক) অর্ডার করুন</Link></li>
        </ul> */
      }
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      